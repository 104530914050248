/** @jsx jsx */
import { graphql } from 'gatsby'
import { jsx } from 'theme-ui'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import PersonBio from 'src/components/personBio'

const Consulting = ({ data }) => {
  return (
    <Layout>
      <SEO title="Консалтинг" />
      <div
        sx={{ variant: 'boxes.container', maxWidth: 'content', pb: 4, pt: 6 }}
      >
        <h1>Консалтинг</h1>
        <p>
          <strong>Перемена.медиа</strong> – это не только издание, но и
          консалтинговое агентство для компаний, работающих в сфере образования.
        </p>
        <p>
          Мы работаем с частными и государственными школами, вузами,
          образовательными центрами, а также производителями товаров для детей и
          подростков.
        </p>
        <h2>Наши услуги</h2>
        <ul>
          <li>Коммуникативные и PR-стратегии</li>
          <li>Информационное продвижение</li>
          <li>Образовательные мероприятия</li>
          <li>Брендинг</li>
          <li>Веб-сайты</li>
          <li>Видеопродакшн</li>
        </ul>
        <h2>Преимущества</h2>
        <p>
          Мы эксперты в области новых образовательных технологий и хорошо
          разбираемся в особенностях рынка.
        </p>
        <ul>
          <li>
            Мы следим за современными образовательными трендами и инициативами
            («Атлас новых профессий», 21st century skills, MOOC, PBL, чемпионаты
            по робототехнике, IT-хакатоны, исследования PISA, движение World
            Skills, исследования UNICEF, а также WEF);
          </li>
          <li>
            Вокруг нашего издания формируется лояльное экспертное сообщество
            родителей, педагогов и предпринимателей в сфере образования.
          </li>
          <li>
            Наша команда состоит из специалистов с опытом работы в PR,
            маркетинге, журналистике, образовательных стартапах и IT-разработке.
          </li>
        </ul>
        <h2>Еще одна особенность</h2>
        <p> Мы имеем реальный опыт работы с детьми и родителями.</p>
        <p>Мы запускаем собственные образовательные стартапы.</p>
        <p>
          Мы ведем курсы для подростков, а также образовательные программы для
          вузов.
        </p>
        <h2>Наша команда</h2>
        <PersonBio
          name="Айжан Мадиходжаева"
          role="Специалист по интегрированным коммуникациям"
          description="Многолетний опыт работы в PR и журналистике"
          phone="+7 701 721 11 17"
          image={data.aizhan.childImageSharp.fluid}
        />
        <PersonBio
          name="Дия Батенова"
          role="Руководитель образовательных проектов"
          description="Проектный менеджмент и развитие компаний в сфере образования"
          phone="+7 707 853 91 08"
          image={data.diya.childImageSharp.fluid}
        />
        <PersonBio
          name="Ильдар Тапалов"
          role="Основатель образовательных проектов"
          description="Опыт в продвижении образовательных продуктов на рынок"
          phone="+7 707 853 91 18"
          image={data.ildar.childImageSharp.fluid}
        />
      </div>
    </Layout>
  )
}

export default Consulting

export const query = graphql`
  query {
    aizhan: file(relativePath: { eq: "aizhan.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    diya: file(relativePath: { eq: "diya.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ildar: file(relativePath: { eq: "ildar.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
